import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreCtx } from "../../store";
import { joinPromoData, Subscription } from "../../util/promo";
import { PaymentConfirm } from "./payment-confirm/payment-confirm";
import { PaymentInit } from "./payment-init/payment-init";

export enum PaymentStep {
    Init,
    Phone,
    Card,
    Error,
    Success
}

export const SubscriptionOffer = observer(() => {
    const httpService = useStoreCtx().appStore;

    const selectedSubscription: Subscription | null = httpService.selectedSubscription

    const [step, setStep] = useState<PaymentStep>(PaymentStep.Init)
		const navigate = useNavigate();

    function isSelectedPaymentMethod() {
        return [PaymentStep.Phone, PaymentStep.Card].indexOf(step) !== -1
    }

    useEffect(() => {
        requestPromo()
    }, [])

    async function requestPromo() {
        try {
          const response = (await httpService.getSubscriptionList()).data
          const promo = response.promoProductPrices
          httpService._selectedSubscription = joinPromoData(promo)[0]
          if (promo.length === 0) {
            httpService.messageError = response.voucher.promoCodeMessage
						navigate({pathname: "/", search: httpService.getAnalyticsQuery()})
          } else {
              httpService.pushEvent({
              event: 'promocode_success',
              event_name: 'promocode_success',
              request_id: null,
              promocode: httpService.promoCode,
            })
						httpService.messageError = ''
          }
        } catch (err) {
          console.error(err)
					httpService.messageError = ''
					navigate({pathname: "/", search: httpService.getAnalyticsQuery()})
        }
      }
  
    return (
        <div style={{
            background: '#090B3F',
            height: '95vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}>
            {selectedSubscription && (
                <>
                    {step === PaymentStep.Init && (
                        <PaymentInit 
                            subscription={selectedSubscription}
                            onNext={(event) => setStep(event)} 
                        />
                    )}
                
                    {isSelectedPaymentMethod() && (
                        <PaymentConfirm
                            paymentMethod={step}
                            subscription={selectedSubscription}
                            onNext={(event) => setStep(event)} 
                        />
                    )}
                </>
            )}
       </div>
    )
})