import { tariffPeriodTranslate } from '../../../util/helpers'
import { Subscription } from '../../../util/promo'
import { KionButton } from '../../button/button'
import s from './styles.module.scss'

export function KionCard(props: {
    subscription: Subscription
    showButton?: boolean
    onSelect?: (val: Subscription) => void
}) {

  function getTariff(): string {
    return tariffPeriodTranslate(props.subscription.tariffPeriod)
  }

  function onButtonClick() {
    props.onSelect?.(props.subscription)
  }

  function transformPrice(price: number) {
    return price / 100
  }

  return (
    <div 
        style={{ backgroundImage: `url(/images/promo/${props.subscription.image})` }}
        className={s.card}
    >
     <div className={s.age}>
      <span className={s.age__count}>
       { props.subscription.ageRating }
      </span>
     </div>
     <div 
        className={s.card__title}
        dangerouslySetInnerHTML={{__html: props.subscription.name}} 
    />
     <div className={s.card__description}>
      { props.subscription.description }
     </div>
     <div className={s.card__price}>
      <div className={s.main}>
       { transformPrice(props.subscription.discountPrice) }
      </div>
      <div className={s.sub}>
       <div className={s.tariff}>
            ₽ за 30 дней
       </div>
       {props.subscription.price !== props.subscription.discountPrice && (
           <div className={s['old-price']}>
                далее { transformPrice(props.subscription.price) } ₽ в месяц
            </div>
        )}
      </div>

      {props.showButton && (
        <KionButton onClick={onButtonClick}>
          Активировать
        </KionButton>
      )}
     </div>
    </div>
  )
}
